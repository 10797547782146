.welcome-col-left h1,
.welcome-col-left h2,
.col1 h1,
.col4 h1,
.col1 h5,
.col4 h5 {
    line-height: 1.4;
    font-weight: 500;

}

.welcome-col-left h2 {
    font-size: 28px;

}

.col1 h1,
.col4 h1,
.col1 h5,
.col4 h5,
.note p,
.pro-col-col h4,
.pro-col-col p {
    color: #fff ! important;
}

.IndicatorCarouselText h1,
.welcome-col-left h1,
.mpd-col-right h1,
.blue-bar h4,
.Gal-info h2 {
    color: #222;
}

.welcome-col-left h3,
.mpd-col-right h2,
.Gal-info p,
.blue-bar p {
    color: #222 !important;
}


.IndicatorCarouselText h1,
.welcome-col-left h1,
.mpd-col-right h1,
.best-sellers h1,
.best-sellers-home h1,
.inner_body h1 {
    font-size: 40px;

}



.IndicatorCarouselText h1 {
    text-align: center;

    padding: 80px 0 35px !important;

    font-weight: 400;
    margin     : 0 !important;

}

.IndicatorCarouselText em {
    font-style      : normal;
    /*text-transform: uppercase;*/
    font-weight     : 800;
}

.welcome-col-left h1,
.mpd-col-right h1 {


    line-height: 1.3;
}



.welcome-col-left h1,
.welcome-col-left h3,
.mpd-col-right h1,
.mpd-col-right h3 {

    text-align: left;

}

.col1 h1 {
    text-align: left;
    padding   : 70px 0 0 30px;
}

.col4 h1 {
    text-align  : left;
    /*padding   : 30px 100px 0 30px;*/
    padding     : 70px 100px 0 30px;
}

.col1 h5,
.col4 h5 {
    font-weight: 400;
    text-align : left;
    padding    : 0 100px 0 30px;
    font-size  : 16px;
}



.welcome-col-left h1,
.col1 h1,
.col4 h1,
.mpd-col-right h1 {

    font-weight: 400;

}

.col1 h1,
.col4 h1 {
    font-size: 24px;
}

.welcome-col-left h3,
.mpd-col-right h3 {
    /*font-size:26px;*/
    font-size     : 18px;
    font-weight   : 400;
    padding       : 0;
    line-height   : 1.4;
    padding-bottom: 15px;

}

.best-sellers h1,
.best-sellers h4,
.best-sellers-home h1,
.best-sellers-home h4 {
    text-align  : center;
    /*color     : #404040 ! important;*/
}

.best-sellers h1,
.best-sellers-home h1,
.inner_body h1 {
    font-weight   : 500;
    text-align    : center;
    padding-bottom: 0 ! important;
    text-transform:uppercase;
}

.inner_body h2 {
    font-size    : 24px;
    /*font-size  : 18px;*/
    text-align   : left;
    font-weight  : 400;
    /*color      : #404040 ! important;*/

}

.inner_body h4 {
    font-size  : 20px;
    text-align : left;
    font-weight: 400;


}

.inner_body h5 {
    font-size  : 16px;
    text-align : left;
    font-weight: 800;

}

.best-sellers h4,
.best-sellers-home h4,
.inner_body h3 {
    font-size  : 24px;
    font-weight: 400;
    text-align : center;
    padding-top: 0 ! important;
    margin-left: 10px;
}

.success h3 {
    padding: 50px 0 ! important;
}

.blue-bar h4 {
    text-align: center;
    padding   : 30px 30px 0;
}

.Gal-info h2 {
    text-align: left;
}

.blue-bar h4,
.Gal-info h2 {
    display       : block;
    font-size     : 30px;
    font-weight   : 400 ! important;
    margin        : 0;
    /* padding    : 20px 30px 0 30px;*/
    padding       : 15px 30px 0 30px;
}

.Gal-info p {
    /* padding: 0 30px 0 30px;*/
    padding: 0 30px 0 30px;

    line-height: 1.7;
}


.blue-bar p {

    line-height: 1.5;
    padding    : 0 80px 0;
}

.inner_body p {

    line-height: 1.7;
}



.inner_body h6 {
    color: transparent ! important;
}

.note p {

    text-align: center ! important;
    font-size : 14px;

    line-height: 1.7;
}




h3.ft-col-tit {
    margin        : 0 0 20px 0 ! important;
    text-transform: uppercase;

    text-align : left;
    font-weight: bolder;

}



.pro-col-col h4 {

    padding    : 130px 100px 0;
    font-size  : 30px;
    font-weight: 400;
    margin     : 0
}


.pro-col-col p {

    line-height: 1.5;
    padding    : 0 50px 50px;
}

em.best {
    font-style : initial;
    font-weight: 800;
}

@media screen and (max-width: 1365px) and (min-width: 768px) {
    .note p {
        padding: 20px 30px !important;
    }
}

@media screen and (min-width: 1366px) {
    .note p {
        padding: 20px 350px !important;
    }
}

@media screen and (max-width:767px) {

    .IndicatorCarouselText h1,
    .welcome-col-left h1,
    .mpd-col-right h1,
    .best-sellers h1,
    .best-sellers-home h1,
    .inner_body h1 {
        font-size: 26px !important;
    }

    .IndicatorCarouselText h1 {
        color: #fff ! important;
    }

    .welcome-col-left h3,
    .mpd-col-right h3 {
        /*font-size:26px;*/
        font-size: 18px;


    }

    .blue-bar h4,
    .Gal-info h2 {

        font-size: 22px !important;
    }

    .inner_body h4 {
        padding    : 0 30px 20px 30px !important;
        line-height: 1.5 !important;
        font-size  : 18px !important;
        font-weight: 600;
        ;


    }

}