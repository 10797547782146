#header-container {
    display   : block;
    width     : 100%;
    /*margin  :10px auto 0 auto;*/
    background: #ffffff;
    position  : relative;
    margin-top: -25px;
    transition: 0.2s;
    z-index   : 20000;
    height    : 0px ! important;

}





.header-logo-navbar {
    display: flex;
    width  : 1147px;
    margin : 30px auto 0 auto;
}

.header-left,
.header-right {
    display: block;
    float  : left;
}

.header-left {
    width: 26%;

}

.header-left img {
    width  : 80%;
    padding: 20px;

}

.header-right {
    width: 100%;

}