.Gallery-grid {
    display: block;
}

.Gallery-grid-mob {
    display: none;
}



@media screen and (max-width: 3000px) and (min-width: 768px) {
    .mob-menu {
        display: none;
    }

    .mpd-section-mob {
        display: none;
    }




}




@media screen and (max-width: 926px) and (min-width: 768px) {
    #navbar li {
        /*padding: 10px 11px !important;*/
        padding: 10px 7px !important;
    }

    .welcome-copy {
        padding: 30px !important;
    }

    .note {
        width: 90% !important;
    }

    .welcome-col-left h1 {
        padding-top: 0 !important;
    }

    .welcome-col-left {
        height: auto !important;
    }

    .Home-section1 img,
    .products-container-home,
    .best-sellers-home,
    .best-sellers,
    .products-container,
    .products-container-home {
        width: 100% !important;
    }

    .pro-col1,
    .pro-col2,
    .pro-col3,
    .pro-col4,
    .pro-col5,
    .pro-col6,
    .pro-col7 {
        width: 31% !important;
    }

    .main-banner {
        height: auto !important;
    }

    .line_bt a,
    .download_btn a {
        font-size     : 16px !important;
        width         : 60% !important;
        padding       : 5px !important;
        text-transform: initial !important;
    }

    .slide-steps img,
    .slide-steps h5,
    .slide-steps p {
        margin     : 200px 0 10px 10px !important;
        width      : 55% !important;
        margin-left: 35% !important;

    }
}


@media screen and (max-width:849px) {
    .section-right img {
        margin : auto;
        display: block;
        width  : 35%;
    }


}

@media screen and (max-width: 1023px) and (min-width: 768px) {
    .col2 {
        display: none !important;
    }

    .welcome-4_col {
        width: 33.33% !important;
    }
}

@media screen and (max-width: 925px) and (min-width: 768px) {
    #navbar li a {
        font-weight: 500 !important;
    }

    .header-left {
        width: 18% !important;
    }

    #navbar li a {
        font-size: 11px !important;
    }


    .header-caption img {

        top: 60px !important
    }

    .inner-body-container {
        width: 90% !important;
    }

    .best-sellers h1,
    .best-sellers-home h1,
    .inner_body h1 {
        font-size: 35px !important;

    }

    .formRow h4 {
        text-align: center !important;
        ;
    }

    .footer-container {
        width: 100% !important;
    }

    .formInput {
        width: 43% !important;
    }

}



@media screen and (max-width:767px) {
    .spacer {
        height: 90px;
    }


    .colour-col img {
     
    
        /*max-width: 80%;*/
        max-width: 100% ! important;
      
    
    
    }

    .col1,
.col2,
.col3,
.col4,
.col5,
.col6,
.col7,
.col8,
.col9 {
 
    margin:50px auto ! important;
}

.first img, .second img, .third img,
.Fourth img, .fifth img, .sixth img,
.seventh img, .eighth img, .ninth img{
    width:100%  ! important;
    max-width:100% !important;
}

   .grayscale-image {
        width: 180px ! important;
        height: auto !important;
         filter: grayscale(0%) ! important;
        /*filter: grayscale(100%);
        transition: filter 0.3s ease;*/
       
    }
    
   /* .grayscale-image:hover {
        filter: grayscale(0%);
        transform: scale(5); /* Increases size by 10% 
        
    }*/

    .more a {
        display      : block !important;
        margin       : auto !important;
        width        : 25% !important;
        margin-bottom: 20px !important;
    }


    .back-btn a img {
        width  : 35% !important;
        display: block;
        margin : 0 auto 60px auto;
        padding: 0 !important;
    }

    .react-multiple-carousel__arrow {
        position: relative;

        border-radius: 35px;

        border: 0;

        min-width : 43px;
        min-height: 43px;
        opacity   : 1;
        cursor    : pointer;
    }

    .react-multiple-carousel__arrow--left {

        left: calc(40% + 1px) !important;
    }


    .react-multiple-carousel__arrow--right {
        right: calc(40% + 1px) !important;
    }

    #navbar li {
        list-style: none;
        display   : inline-block;
        padding   : 10px 12px;
        text-align: center;

        border-bottom: 1px dotted #fff;
        width        : 75%;
    }



    .mpd-section-mob {
        display: block !important;
    }

    .mpd-section-desk,
    .world-class img {
        display: block !important;
    }

    .mpd-col-left img {
        /* width  : 100% !important;
                    padding: 0 !important;*/
        display: none;

    }

    .hm-header {
        margin-top: 20px;
    }

    .Gal-info p,
    .Gal-info h2 {

        padding: 20px 20px 0 20px !important;
    }

    .Gal-info h2 {
        font-size: 20px !important;
    }

    .Gal-info {
        padding-bottom: 5px !important;
    }




    .ft-container {
        margin-bottom: 40px !important;
    }

    .line-btn a,
    .mpd-btn a {
        display       : block;
        float         : initial !important;
        padding       : 10px !important;
        border        : 1px solid #0e2a4b;
        text-align    : center;
        color         : #222;
        text-transform: uppercase;
        font-size     : 14px !important;
        width         : 45%;
        margin        : auto !important;

    }

    .welcome-col-left h1,
    .welcome-col-left h3,
    .mpd-col-right h1,
    .mpd-col-right h3 {
        text-align: center !important;
    }


    .welcome-col-left h3 {
        font-size: 16px !important;
    }

    .welcome-col-left h1 {
        font-size: 26px !important;

    }



    .IndicatorCarouselText h1 {
        padding: 80px 35px 20px !important;
    }

    .IndicatorCarouselCard {

        padding: 30px 0;

    }

    .sml-src-container {
        margin-bottom: 0 !important;
    }

    .header-left img,
    .header-left {
        width: 100% !important;
    }

    .home-container {
        margin: 0 !important;
    }

    /*video, 
video.home-vdo-banner{
width:100% !important;
}*/

    video.home-vdo-banner,
    .home-vdo-banner {

        top       : 0;
        left      : 0;
        min-width : 60% !important;
        min-height: 60% !important;
        transform : translateX(calc((-85% - 10vw) / 2)) !important;

        background-position: center;
    }


    .header-logo-navbar {
        width: 100% !important;
    }

    .slide-step_r1 {

        padding: 80px 0 20px 0 !important;

    }

    .home-container {
        width: 100% !important;
    }



    .welcome-copy {
        padding: 30px 30px 0 30px !important;
    }

    .welcome-col-right img,
    .mpd-col-right img {
        width  : 100% !important;
        padding: 0 !important;
        margin : 0 auto 20px auto !important;
        display: block;
    }

    .welcome-container,
    .welcome-container-gnt {
        display: block !important;
        float  : left !important;
    }



    .mpd-col-left,
    .mpd-col-right {
        width: 100% !important;
    }

    .gnt-ban {
        margin-top: -200px !important;
    }

    .slide-steps img {
        width: 35% !important;
    }


    .mpd-btn a {
        margin-bottom: 30px !important;
    }

    .mpd-copy {
        padding: 10px 30px 0 30px !important;
    }






    .IndicatorCarousel {
        display: block !important;
        float  : left !important;
    }


    .range-title {
        float: initial !important;
    }

    .welcome-col-left {
        height: auto ! important;
    }

    .section-left,
    .section-right {
        margin-top: 0 !important;
    }


    .footer-col4 {
        padding-bottom: 30px !important;

    }

    .copy-right {
        font-size: 25px !important;
    }


    #navbar {
        display        : flex ! important;
        flex-direction : column ! important;
        align-items    : flex-start;
        justify-content: flex-start;
        margin         : auto;
        position       : fixed;
        top            : 11px;
        right          : -350px;
        width          : 250px;
        background     : #0e2a4bf0 !important;
        height         : 100vh;
    }


    #navbar.active {
        right: 0px;
    }

    #navbar li {

        margin: 5px 0 0 20px ! important;

    }



    .Home-section1-mob,
    .mob-ban img {
        display: none !important;
    }

    #navbar li a {
        color    : #fff ! important;
        font-size: 12px ! important;

    }

    #mobile {
        display   : block ! important;
        margin-top: 40px;
    }

    #mobile i {
        font-size  : 24px;
        cursor     : pointer;
        /*padding  : 50px 0 0 70px;*/

        color       : #0e2a4b !important;
        float       : right;
        margin-right: 30px;
    }




    .pro-col1,
    .pro-col2,
    .pro-col3,
    .pro-col4,
    .pro-col5,
    .pro-col6,
    .pro-col7 {
        width : 92% ! important;
        margin: 0 15px 20px 15px ! important;
    }

    .col1 h5,
    .col4 h5 {
        padding   : 0px 30px 0 30px !important;
        text-align: center !important;

    }


    #navbar {
        /* margin-top: 100px !important;*/
        /*margin-top: 100px !important;*/
        margin-top: 75px !important;
    }


    .Gallery-grid {
        display: none;
    }

    .Gallery-grid-mob {
        display: block;
    }

    .fas.fa-bars {
        display    : block;
        width      : 30px;
        height     : 5px;
        margin-top : 5px;
        background : #0e2a4b !important;
        margin-left: 80px;

    }

    .fas.fa-times {
        display   : block;
        background: #f2584d !important;
        width     : 30px;
        height    : 5px;
        margin-top: 5px;
        background: gray;
        ;
        margin-left: 80px;
    }



    .staff {
        background: contain ! important;
        height    : 210px ! important;
    }

    .year-logo {
        width: 25% ! important;
    }

    .inner_body p,
    .inner_body h2,
    .inner_body ul li {
        /* margin: 0 20px ! important;*/
        padding   : 0 30px;
        text-align: center;
    }

    .inner_body h2 {
        font-size     : 24px ! important;
        /* padding-top: 30px ! important;*/
        padding       : 10px 30px;

    }

    .Range-col {
        display: block !important;
    }

    .Range-col1,
    .Range-col2,
    .Range-col3 {
        display: block !important;
        margin : 0 30px !important;
    }

    .Range-col1 label,
    .Range-col2 label,
    .Range-col3 label {

        display: block;
        float  : left;
        width  : 100%;
    }




    .inner_body h5 {
        text-align : center ! important;
        line-height: 1.5;
        padding    : 0 20px;
    }

    .inner_body h4 {
        text-align: center !important;
    }









    ul.d-flex {
        display: none;
    }



    .Layer_Banner img {
        width  : 85% !important;
        margin : 30px auto !important;
        padding: 0 20px !important;
    }

    .watch-video {
        float : inherit ! important;
        width : 50% ! important;
        margin: 10px auto ! important;
    }

    iframe,
    .c-video-inn {
        height: autox !important;
    }

    .sets-icon img {
        float  : initial !important;
        margin : auto;
        width  : 12% ! important;
        padding: 0 20px 0 20px ! important;
        display: block;
    }



    .sets-icon p,
    .inner_body h2 {
        text-align: center;
        ;
    }

    .section-right img {
        margin : auto;
        display: block;
        width  : 50%;
    }




    .inner-body-container {
        width: 100% ! important;
    }

    .certificate {
        width  : 50% ! important;
        margin : 0 auto 30px auto;
        float  : initial ! important;
        display: block;
    }

    .year-logo {
        float    : right ! important;
        margin   : 30px auto ! important;
        display  : block;
        max-width: 20%;

    }

    .built-logo {
        float    : initial ! important;
        margin   : 30px auto ! important;
        display  : block;
        max-width: 50%;

    }

    .edge {
        width : 90% ! important;
        margin: auto !important;
        float : initial !important;
    }

    .desk-menu {
        display: none;
    }

    .mob-menu {
        display: block;
    }



    .header-banner-mob,
    .header-banner-mob img {
        display        : block ! important;
        background-size: cover;
        width          : 100%;
        height         : 400px;
        margin         : 20px 0;
    }

    .header-caption-mob {
        display: block ! important;
    }

    .header-caption-mob img {
        position: absolute;
        width   : 100%;
        top     : 110px;
        display : block ! important;
        height  : auto ! important;
    }

    .main-banner-mob {
        display       : block ! important;
        /*    position: absolute;*/
        top           : 0;
        overflow      : hidden;
        min-height    : 80%;
        z-index       : 2;

        /*padding-top: 31%;*/
        width: 100%;
        left : 0px;

        background-position: center !important;

    }

    .mob-ban img {
        margin-top: 60px !important;
    }

    .main-banner-mob img {
        width      : 80%;
        padding-top: 0;
        display    : block;
        margin     : auto;

        /* width      : 65%;
        padding-top: 14%;
        display    : block;
        margin     : auto;*/
    }

    .home-caption img {
        margin-top   : -750px !important;
        margin-bottom: 160px !important;
    }

    .mob-ban img {
        margin-top: 60px !important;
    }

    /*

    .best-sellers h1,
    .best-sellers-home h1,
    .inner_body h1 {
        font-size: 32px !important;
    }
        */

    .welcome-col-left h2 {
        font-size : 20px ! important;
        text-align: center;
    }

    .main-banner,
    .Home-section1,
    .Home-section1 img,
    .col2,
    .col3,
    .mobmenur-container,
    .header-banner {
        display: block ! important;
    }

    .colur-name,
    .colur-name1 {

        position  : relative ! important;
        z-index   : inherit;
        margin-top: -39px ! important;
    }

    img.pcg-logo {

        width  : 90% !important;
        margin : 20px auto !important;
        display: block !important;
        float  : initial !important;
    }

    .colour-grid {
        display        : flex !important;
        width          : 90% !important;
        margin         : auto;
        flex-direction : column !important;
        justify-content: center;
        gap            : 1rem !important;
    }


    .colour-col img {
        
        width        : 100% ! important;
        margin-bottom: 10px;
    }

    .note {
        flex-direction: column !important;
        width         : 60% !important;
    }

    .note p {
        padding: 20px ! important;
    }

    .col1 h1,
    .col4 h1,
    .col4 h3,
    .col4 h5 {
        text-align: center;
    }

    .col1 h1 {
        /* padding: 100px 0 0 0 ! important;*/
        padding: 35px 0 0 0 ! important;
    }

    .line_bt a,
    .download_btn a {
        margin: auto ! important;
    }

    .best-sellers-home,
    .best-sellers,
    .products-container,
    .products-container-home {
        width: 100% !important;
    }

    .Home-section1-mob img {
        display: none !important;
    }

    .Home-section1-mob {
        display: block ! important;
        width  : 100%;

        opacity: 0.9;
    }

    .best-sellers,
    .products-container,
    .welcome-4_col {
        width: 100% ! important;
    }

    .best-sellers h1,
    .inner_body h1 {
        /* font-size: 32px ! important;*/
        font-size: 22px ! important;
    }

    .welcome-col-left,
    .welcome-col-right {
        width: 100% ! important;
    }




    .frame-style {

        height: calc(1200vh - 95px) ! important;
        width : 100% ! important;


    }

    .frame-style1 {
        height: calc(120vh - 25px) ! important;
        width : 100% ! important;
    }

    .frame-style2 {
        height: calc(300vh - 25px) ! important;
        width : 100% ! important;
    }




    .footer-container {
        display: block ! important;
        width  : 100% ! important;
    }

    .footer-col1,
    .footer-col2,
    .footer-col3,
    .footer-col4 {
        width     : 90% ! important;
        display   : block;
        text-align: center ! important;
        margin    : 30px auto ! important;
        padding   : 0 ! important;
        float     : initial ! important;
    }



    .footer-col1 {
        margin-top: 30px auto ! important;
    }

    .footer-col4 {
        margin-bottom: 35px ! important;
    }

    .footer-col1 img {
        margin    : auto;
        text-align: center;
        width     : 70% ! important;
        display   : flex;
    }

    .ft-info {
        width     : 100% ! important;
        text-align: center ! important;
        float     : initial ! important;
        margin    : 30px auto ! important;
    }

    h3.ft-col-tit {
        text-align: center ! important;
        margin    : 0 ! important;
    }

    .privacy {
        margin: 30px auto ! important;
    }

    ul li.social {
        margin: 30px 0 30px 30%;
    }

    .social a img {
        width        : 8% ! important;
        margin-bottom: 30px !important;

    }


    li.dealer {

        padding        : 15px 30px !important;
        list-style-type: circle !important;
        list-style     : circle !important;
        margin         : 0 !important;
    }

}

@media screen and (max-width: 1400px) and (min-width: 1366px) {

    .home-caption img {
        margin-top: -725px !important;
    }


    .slide-steps img {
        margin: -15px 0 10px 10px !important;
    }



    .GallerySection {
        height: 800px !important;
    }

    .mpd-copy {
        padding: 100px 160px 0 30px !important;
    }


    .mpd-btn a {
        margin: 20px 0 0 30px !important;
    }

    .welcome-col-left h1,
    .mpd-col-right h1,
    .IndicatorCarouselText h1 {
        /* font-size: 32px !important;*/
        font-size: 32px !important;
    }

    .welcome-col-left h3,
    .mpd-col-right h3 {
        font-size: 22px !important;
        ;
    }

    .blue-bar h4,
    .Gal-info h2 {
        font-size: 26px !important;
    }

    .Home-section1 {
        margin-top: -216px !important;
    }

    .IndicatorCarousel img,
    .Gal-info {
        width: 80% !important;
    }

    .Gal-info img {
        width     : 27% !important;
        margin-top: -30px !important;
    }

    .welcome-copy {
        padding: 110px 30px 0 115px !important;
    }

    .line-btn a {
        margin: 20px 0 0 115px !important;
    }
}

@media screen and (max-width: 1400px) and (min-width: 1367px) {


    .Home-section1 {
        margin-top: -195px !important;
    }

    .welcome-copy {
        padding: 110px 30px 0 115px !important;
    }

    .line-btn a {
        margin: 20px 0 0 115px !important;
    }
}

@media screen and (max-width:1400px) {






    .main-banner img {
        width      : 40% ! important;
        padding-top: 30% ! important;

    }

    /* .line-btn a{
     margin:20px 0 0 160px !important;
    }*/

    .pro-col-col,
    .products-container-home {
        height: 450px !important;
    }

    .blue-bar p {
        padding: 0 50px 0 !important;
    }

    .pro-col-col h4 {
        padding: 125px 50px 0 !important;
    }




    /*   .welcome-copy{
       padding:110px 30px 0 160px !important;
    }*/

    .Home-section1 img {
        width: 90%;
    }

    .welcome-4_col {
        height: 320px ! important;
    }




    .more a {
        width: 25% !important;
    }

    .col1 h1,
    .col4 h1 {
        /*font-size: 26px;*/
        font-size: 22px;
    }

    .welcome-col-left h2 {
        font-size: 18px;
    }

    .line_bt a,
    .download_btn a {
        /*width: 50% ! important;*/
        width: 60% ! important;
    }

    .col4 h1,
    .col4 h3 {
        /*padding-right: 30px ! important;*/
        padding-right: 10px ! important;
    }



    .col1 h1,
    .col4 h1 {
        padding: 50px 30px 0 30px !important;
    }

    .col1 h5,
    .col4 h5 {
        padding  : 0 10px 0 30px !important;
        font-size: 16px !important;
    }


}

/*
@media screen and (max-width: 1400px) and (min-width: 1025px) {

    .slide-steps img,
    .slide-steps h5,
    .slide-steps p {
        margin: 40px 0 10px 10px ! important;
    }

    .col1 h5,
    .col4 h5 {
        padding: 0 50px 0 30px;
    }

    .col1 h1 {
        padding: 28px 0 0 30px;
    }


}
*/

@media screen and (max-width: 1365px) and (min-width: 927px) {

    #navbar li {
        /*padding: 10px 11px !important;*/
        padding: 10px 11px !important;
    }
}


@media screen and (max-width: 1365px) and (min-width: 901px) {

    .Home-section1 img {
        width: 100% !important;
    }

    .main-banner {
        height: auto !important;
    }

    #navbar li a {
        font-size: 12px !important;
    }



    .best-sellers-home,
    .best-sellers,
    .products-container,
    .products-container-home {
        width: 100% !important;
    }

    .pro-col1,
    .pro-col2,
    .pro-col3,
    .pro-col4,
    .pro-col5,
    .pro-col6,
    .pro-col7 {
        width: 31.7% !important;
    }

    .col1 h1,
    .col4 h1,
    .welcome-col-left h2 {
        font-size: 20px !important;
    }

    .col1 h5,
    .col4 h5 {
        padding  : 0 25px 0 30px !important;
        font-size: 15px !important;
    }

    .line_bt a,
    .download_btn a {
        font-size: 14px !important;
        width    : 60% !important;

    }

    .slide-steps img {
        margin     : 180px 0 10px 10px !important;
        margin-left: 45% !important;
    }

    .section-left,
    .section-right {
        width: 48% !important;
    }

    .inner_body {
        width : 90% !important;
        margin: 30px auto !important;
    }

    .formInput {
        width: 44% !important;
    }

    .formInputAd,
    .formInputMsg {
        width: 94% !important;
    }

    .inner-body-container {
        width: 100% !important;
    }

    .header-caption img {

        top: 60px !important
    }

    .footer-container {
        width: 100% !important;
    }
}

/*@media screen and (max-width: 1366px) and (min-width: 1024px) {*/
@media screen and (max-width: 1365px) and (min-width: 1024px) {


    .welcome-copy {
        padding: 45px 30px 0 70px !important;
    }


    .mpd-copy {
        padding: 45px 160px 0 30px !important;
    }

    .mpd-col-right h1,
    .welcome-col-left h1,
    .IndicatorCarouselText h1 {
        font-size: 26px !important;
    }

    .mpd-col-right h3,
    .welcome-col-left h3 {
        font-size: 16px !important;
    }


    .line-btn a {
        margin: 10px 0 0 70px !important;
    }

    .line-btn a,
    .mpd-btn a {
        padding: 15px 25px !important;
    }

    .Gal-info h2,
    .blue-bar h4 {
        font-size: 22px !important;
    }

    .more a {
        padding: 15px 25px !important;
        width  : 32% !important;
    }

    .GallerySection {
        height: 810px !important;
    }

    .Home-section1 {
        margin: 0 auto auto !important;
    }

    .home-caption img {
        margin-top: -530px !important
    }


    .mpd-col-left img,
    .welcome-col-right img {
        padding: 50px 50px !important;
    }


}