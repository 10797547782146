a {
    text-decoration: none !important;
}

.grayscale-image {
    width: 50px;
    height: 10px;
    /*filter: grayscale(100%);*/
    /*transition: filter 0.3s ease;*/
    /*transform-origin: 30% 30%;*/
  transition: transform 1s, filter .15s ease-out;
}

.grayscale-image:hover {
    filter: grayscale(0%);
    /*transform: scale(1.7); /* Increases size by 10% */
    transform: scale(1.5);
}

.glider-slide {
    min-width: 80px !important;
}

.glider.draggable {
    overflow: hidden;
}

.glider.draggable {
    background: #fff !important;
}

.glider-slide {
    margin: 0 1px !important;
}

.glider-contain {
    width: 100% !important;
}

.glider-next {
    right: 15px !important;
    left : auto;

}

.glider-prev {
    left : 15px !important;
    right: auto;

}

.glider-next,
.glider-prev {
    top          : 40% !important;
    font-size    : 25px !important;
    background   : #ffffffb5 !important;
    border-radius: 100px !important;
    padding      : 5px 12px !important;
    color        : #222 !important;
}

.thumb-container {
    display   : block;
    width     : 100%;
    margin    : 0 auto 20px;
    text-align: center;
}




.glider-next.disabled,
.glider-prev.disabled {

    color: transparent !important;
}

.slide__content img {
    opacity: 0.5;
}

.--current-selected img {
    opacity: 1 !important;
}

.photos-gallery {
    margin-top: 50px;
}

em.abt {
    font-size  : 16px;
    text-align : left;
    font-weight: 800;
    font-style : normal !important;
}


.inner-container {
    display: block;
    width  : 100%;
    margin : 10px auto;
}


ul li.ft-col-list a {
    text-decoration: none ! important;
}

.frame-style {

    height: calc(530vh - 95px);
    width : 100% ! important;


}

.frame-style1 {
    height: calc(100vh - 25px);
    width : 100% ! important;
}

.frame-style2 {
    height: calc(200vh - 25px);
    width : 100% ! important;
}


.frame-style3 {
    height: calc(130vh - 25px);
    width : 100% ! important;
}

.header-banner {
    display: block;
    width  : 98%;
    margin : 20px auto;

    background-size: cover;
}

.header-banner img {
    width     : 100%;
    margin-top: 100px;
}


.header-banner-mob,
.header-caption-mob {
    display: none;
}


.inner-body-container {
    display: flex;
    width  : 70rem;
    margin : auto;
}



sup.tit_super {
    font-size: 30px ! important;

}

sup.super {
    font-size: 15px ! important;
}

.body-container {
    display              : flex;
    /* background           : url('../components/img/water.png') no-repeat; */
    background-position  : right;
    background-position-y: bottom;
}

.body-top-container {
    display      : flex;
    width        : 100%;
    background   : linear-gradient(90deg, #fff, rgb(255, 248, 210) 35%, rgb(255, 182, 105) 100%);
    border-radius: 5px;
    ;

}

.colour-grid {
    display: flex;

    width         : 100%;
    margin-bottom : 15px;
    gap           : 1rem;
    flex-direction: column;
}

.colour-col img {
    display: flex;

    /*max-width: 80%;*/
    max-width: 45%;
    margin   : 20px auto;


}

.col1,
.col2,
.col3,
.col4,
.col5,
.col6,
.col7,
.col8,
.col9 {
    display      : block;
    background   : #222;
    width        : 100%;
    border-radius: 5px;
    background: transparent !important;
    margin:100px auto;
  /*border: 1px solid #222;*/
}

.colour-col img {
    border-radius: 5px;
}



.colur-name h4,
.colur-name p {
    text-align: center;
    margin    : 10px;
    color     : #222 ! important;
}

.colur-name p {
    padding: 0 20px;
    display:none ! important;
}

.colur-name,
.colur-name1 {
    display: none ! important;

    position: relative;

    padding   : 5px 10px;
    text-align: center;
    color     : #222;



    font-size: 14px;

}

li.dealer {
    padding: 8px 0;
    margin : 0 !important;
}

img.pcg-logo {
    float: right;
    width: 35%;
}

.colur-name {
    margin: 0 0 auto 0px;
}

.colur-name1 {
    margin: 0 0 auto 0px;
}

.note {
    display       : flex;
   
    margin        : 30px auto;
    flex-direction: row;
  

}

.note-col1,
.note-col2,
.note-col3,
.note-col4 {
    display        : flex;
    padding        : 15px;
    justify-content: center;
    flex-grow      : 1;
    border-radius  : 5px;
    text-transform: uppercase;
    
}


.note-col1 img,
.note-col2 img,
.note-col3 img,
.note-col4 img {
    width: auto;
}

.note-col1 a:hover,
.note-col2 a:hover,
.note-col3 a:hover,
.note-col4 a:hover {
    border: 2px solid rgb(24, 117, 202);
}

.copy-right {
    font-size: 30px;
}

.header-caption img {
    position: absolute;
    width   : 50%;
    top     : 150px;
}

.standard-grid {
    margin-bottom: 50px;
}

.year-logo,
.built-logo {
    float      : right;
    margin-left: 20px;
}


.edge {
    float      : right;
    margin-left: 20px;
}

.certificate {
    width: 20%;
}


.sets-icon img {
    float: left;
    width: 6%;

    padding: 0 30px 20px 0;
}



.inner_body {
    /* margin-top: 50px;*/
    margin:auto;
    text-align:center;

}


.pcg {
    float: left;
}

.watch-video {
    display: block;
    float  : left;
    width  : 100%;
    margin : 10px 0;
}

.watch-video a {
    display        : block;
    padding        : 20px;
    background     : #033051 !important;
    color          : #fff;
    cursor         : pointer;
    font-weight    : 500;
    text-transform : initial;
    margin         : 0 0 10px 0;
    float          : left;
    text-decoration: none;
    ;
}





.Layer_Banner img {
    margin: 50px 0;
}




.test-container {
    display        : flex;
    justify-content: center;
    gap            : 20px;
}

.react-multiple-carousel__arrow,
.react-multiple-carousel__arrow::before {
    background: #0e2a4b !important;
    color     : #fff !important;
}