.home-container {
    display: block;
    width  : 100%;
    margin : 0;
}

.infodata {
    display: none;
}

.main-banner {
    display: block;
    width  : 100%;
    margin : 20px auto;
    height : 780px;

    background-size        : cover;
    /*background-attachment: fixed;*/
}

.main-banner-mob,
.Home-section1-mob img,
.Home-section1-mob {
    display: none;
}

.main-banner img {
    width      : 35%;
    padding-top: 17% !important;
    display    : block;
    margin     : auto;

}



.back-btn a img {
    width  : 15%;
    padding: 5px 0 20px 0;
}

.pro-col-col,
.pro-col1,
.pro-col2,
.pro-col3,
.pro-col4,
.pro-col5,
.pro-col6,
.pro-col7 {
    display       : block;
    float         : left;
    /*width       : 23%;*/
    /*width       : 24.8%;*/
    width         : 30%;
    text-align    : center;
    margin        : 15px;

}

.pro-col-col {
    margin-right: 3px;
}

.pro-col-col {
    background: #0e2a4b;
    height    : 500px;
}




.pro-col1 img,
.pro-col2 img,
.pro-col3 img,
.pro-col4 img,
.pro-col5 img,
.pro-col6 img,
.pro-col7 img {

    width: 100%;
}

.react-multiple-carousel__arrow--right {
    right: calc(2% + 1px) !important;
}

.react-multiple-carousel__arrow--left {
    left: calc(2% + 1px) !important;
}

.react-multiple-carousel__arrow {
    background: rgba(255, 255, 255, 0.5) !important;
}

.react-multiple-carousel__arrow::before {
    color: #222 !important;
}

.welcome-container,
.welcome-container-gnt {
    display       : flex;
    width         : 100%;
    /*margin      : 40px auto;*/
    margin        : auto;
    flex-direction: row;
    ;

    margin-bottom: 2px;


}



.welcome-col-right img {
    width: 100%;


}

.welcome-col-left,
.welcome-col-right,
.mpd-col-left,
.mpd-col-right {
    display: block;
    float  : left;
    width  : 50%;

}

.main-more a,
.more a {
    display      : inline-flex;  
    text-align   : center;
    color        : #222;
    border-radius: 5px;

    margin-bottom: 20px;
}

.main-more a{
    background   : #ff6800;
    padding      : 15px;
}

.more a{
background:transparent;
border:1px solid #222;
padding      : 10px;
}

.main-more a:hover {
    background: #ff6800ab;
}

.more a:hover {
    border:1px solid #22222291;
    color: #22222291;
}


.welcome-copy {
    padding: 120px 30px 0 370px;
}

.mpd-copy {
    padding: 150px 250px 0 30px;
}

.welcome-col-left {

    background-size    : cover;
    background-position: center;

    margin-bottom: 20px;
}

.welcome-4_col {
    display: block;
    float  : left;

    width     : 25%;
    background: #05b4c9;
    text-align: center;

    height: 445px;
}

em.space {
    color  : transparent !important;
    height : 20px;
    display: block;
}

em.space2rw {
    color  : transparent !important;
    height : 40px;
    display: block;
}

.col1 {
    background: #0e2a4b;
}

.col2 {

    background-size: cover;
}

.col3 {

    background-size: cover;
}

.col4 {
    background: #05b4c9;
}

.line-btn a,
.mpd-btn a {
    display       : block;
    float         : left;
    padding       : 20px 30px;
    border        : 1px solid #0e2a4b;
    text-align    : center;
    color         : #222;
    text-transform: uppercase;

}

.line-btn a {
    margin: 30px 0 0 250px;
}

.mpd-btn a {
    margin: 30px 250px 0 30px;
}

.home-caption img {
    display     : block;
    text-align  : center;
    margin-top  : -900px;
    margin-left : auto;
    margin-right: auto;

    color   : #fff;
    position: relative;
}

.Home-section1 {

    background: #0e2a4b;
    display   : block;
    margin    : 100px auto auto;
    opacity   : .8;
    position  : relative;
    z-index   : 200;
}

.main-banner video {
    position : relative;
    max-width: 100%;
    width    : 100% !important;
}

.Home-section1 img {
    height : auto;
    margin : auto;
    display: block;
    width  : 1147px;
}

.blue-bar {
    background   : #0e2a4b29;
    padding      : 15px;
    color        : #0e2a4b;
    text-align   : left;
    font-weight  : 800;
    margin-top   : -5px;
    border-radius: 0 0 10px 10px;
}



.best-sellers-home,
.best-sellers,
.products-container-home {
    background-image: linear-gradient(#0e2a4b29, white, #0e2a4b29);
    height          : 500px
}

.best-sellers-home,
.best-sellers,
.products-container,
.products-container-home,
.range-container {

    width  : 100%;
    display: block;
    margin : 2px auto;


}

.best-sellers,
.products-container {
    float: left;
}

.line_bt a,
.download_btn a {
    display       : block;
    border        : 1px solid #fff;
    padding       : 10px;
    color         : #fff;
    text-transform: uppercase;
    text-align    : ce;
    ;

    text-decoration: none;
    margin-left    : 30px;
}


.line_bt a {
    width: 30%;
}

.download_btn a {
    width: 40%;
}

.GallerySection {
    display: block;
    width  : 100%;

    height: 899px;
}

.IndicatorCarousel img {
    display: block;
    height : auto;
    float  : initial;
    margin : auto !important;
    width  : 70%;
}

.IndicatorCarousel {
    display: block;
    float  : initial;
    width  : 100%;

}

.Gal-info {
    display       : block;
    width         : 70%;
    /*background  : #d8dde2;*/
    background    : #fff;
    margin        : auto;
    text-align    : left;
    padding-bottom: 20px;
    height        : auto;
}


.Gal-info img {
    width       : 37%;
    height      : auto;
    float       : right;
    display     : block;
    padding-left: 5px;
    /*margin-top:-25px !important;*/
    margin-top  : -50px !important;
}


.gframe {
    height: 1700px;
}

.cframe {
    /*height: 1000px;*/
    height: calc(110vh - 95px) !important;
    width : 100% !important;

}

.gframe {
    /*height: 1000px;*/
    height: calc(220vh - 95px);
    width : 100% !important;

}

.gframe,
.cframe {
    display: block;
    width  : 100%;
    border : 0 !important;

}




@media screen and (max-width:767px) {
    .gframe {

        height: calc(250vh - 95px) !important;
    }

    .cframe {

        height: calc(120vh - 95px) !important;
    }
}

@media screen and (max-width: 1800px) and (min-width: 768px) {

    .gframe {
        /*height: 1000px;*/
        height: calc(220vh - 95px) !important;
        width : 100% !important;

    }


}